import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useIonToast, IonItem, IonItemSliding, IonItemOptions, IonItemOption, IonLabel, IonAlert } from '@ionic/react';

const UCard = (props) => {

    const navigate = useNavigate();

    const [present] = useIonToast();
    const presentToast = (custommessage, customclass, customduration = 2000) => {
        present({
            message: custommessage,
            duration: customduration,
            position: 'bottom',
            className: customclass,
        });
    };

    const [deleteID, setDeleteID] = useState("0");

    const [showAlert, setShowAlert] = useState(false);

    const isLoggedIn = JSON.parse(localStorage.getItem('logged-in')) || false;

    //console.log(props.data);

    const editUser = (id) => {
        localStorage.setItem("scaner-id", props.data.id);
        navigate(`/user/en`, { replace: true });
    }

    const deleteUser = (id) => {
        setDeleteID(id);
        setShowAlert(true);
    }

    const doDelete = (state) => {
        //console.log(deleteID, state);
        setShowAlert(false);
        if (state == "confirm") {
            //console.log("will delete User with ID: " + props.data.id);
            let prepareURL = 'https://medical-communications.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=' + props.data.id + '&option=scaner&value=delete';
            fetch(prepareURL)
                .then(response => response.json())
                .then(json => deleteUserData(json, props.data.id))
                .catch(error => presentToast(error));
        }
    }

    const deleteUserData = (resp, id) => {
        const allUser = JSON.parse(localStorage.getItem("all-user") || {});

        let afterDeletion = allUser.data.filter((item) => item.id != id);
        allUser.data = afterDeletion;

        localStorage.setItem("all-user", JSON.stringify(allUser));
        navigate(`/user`, { replace: true });
    }

    return (
        <>
            <IonItemSliding className='user-slider' id={"user-slide-" + props.data.id}>
                <IonItemOptions side="start">
                    <IonItemOption color="primary" onClick={e => editUser(props.data.id)}>Edit</IonItemOption>
                </IonItemOptions>
                <IonItem>
                    <IonLabel>{props.data.name}</IonLabel>
                </IonItem>
                {(props.data.role != "1" || (props.data.role == "1" && props.data.name != isLoggedIn.username)) && <IonItemOptions side="end">
                    <IonItemOption color="danger" onClick={e => deleteUser(props.data.id)}>Delete</IonItemOption>
                </IonItemOptions>}
            </IonItemSliding>
            {showAlert ? <IonAlert
                header="User löschen!"
                message="Den user unwiederruflich löschen?"
                isOpen={showAlert}
                buttons={[
                    {
                        text: "Nein",
                        role: 'cancel',
                        handler: () => {

                        },
                    },
                    {
                        text: "Ja",
                        role: 'confirm',
                        handler: () => {

                        },
                    },
                ]}
                onDidDismiss={({ detail }) => doDelete(detail.role)}
            ></IonAlert> : null}
        </>
    );

}

export default UCard;
