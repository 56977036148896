import './App.css';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IonContent, IonHeader, IonToolbar, IonTitle, useIonToast, IonButton, IonBackButton, IonButtons, IonCard, IonFooter, IonModal, IonList, IonItem, IonLabel } from '@ionic/react';

import UserCards from './Components/UserCards';
import UserData from './Components/UserData';

const UserEditNew = () => {

    const navigate = useNavigate();

    const role = [{ "id": "1", "name": "Admin" }, { "id": "2", "name": "Scaner" }, { "id": "3", "name": "Sponsor" }];

    const [userId, setUserId] = useState(JSON.parse(localStorage.getItem('scaner-id')) || 0);

    const isLoggedIn = JSON.parse(localStorage.getItem('logged-in')) || false;
    let allUser = JSON.parse(localStorage.getItem('all-user')) || {};
    const allEvents = JSON.parse(localStorage.getItem('all-events')) || {};
    const allCompanies = JSON.parse(localStorage.getItem('all-companies')) || {};

    const [present] = useIonToast();
    const presentToast = (custommessage, customclass, customduration = 2000) => {
        present({
            message: custommessage,
            duration: customduration,
            position: 'bottom',
            className: customclass,
        });
    };

    let currUser;
    //console.log(allUser);
    if (Object.keys(allUser).length !== 0) currUser = allUser.data.filter(obj => obj.id == userId);

    const back = () => {
        localStorage.setItem('scaner-id', 0);
        navigate("/user", { replace: true });
    }

    const saveUser = () => {
        const updateUserName = document.getElementById("updateUserName");
        const updateUserPassword = document.getElementById("updateUserPassword");
        const updateUserRole = document.getElementById("updateUserRole");
        const updateUserEvent = document.getElementById("updateUserEvent");
        const updateUserCompany = document.getElementById("updateUserCompany");

        const newUserName = document.getElementById("newUserName");
        const newUserPassword = document.getElementById("newUserPassword");
        const newUserRole = document.getElementById("newUserRole");
        const newUserEvent = document.getElementById("newUserEvent");
        const newUserCompany = document.getElementById("newUserCompany");

        const updateId = JSON.parse(localStorage.getItem('scaner-id')) || 0;

        // console.log("what");

        if (updateUserName && updateUserPassword && updateUserRole && updateUserEvent && updateUserCompany) {
            const name = updateUserName ? updateUserName.value : "";
            const pass = updateUserPassword ? updateUserPassword.value : "";
            const role = updateUserRole ? updateUserRole.value : 0;
            const event = updateUserEvent ? updateUserEvent.value : 0;
            const company = updateUserCompany ? updateUserCompany.value : 1;
            let test = true;
            let message = "";
            if (name == "" || /\s/g.test(name) || name.indexOf(',') > -1) {
                test = false;
                message = "Username darf keine Leerzeichen oder Komma haben";
            }
            if (pass == "") {
                // save without password
            } else {
                if (/\s/g.test(pass) || pass.indexOf(',') > -1) {
                    test = false;
                    message += (message != "") ? "<br>Password darf keine Leerzeichen oder Komma haben" : "Password darf keine Leerzeichen oder Komma haben";
                }
            }
            if (role == 0 || event == 0 || company == 0) {
                test = false;
                message += (message != "") ? "<br>Role und Event müssen bestimmt werden" : "Role und Event müssen bestimmt werden";
            }
            if (test && name != "" && role != 0 && event != 0 && company != 0) {
                // console.log("update with or without password");
                const data = name + "," + pass + "," + role + "," + event + "," + company;
                let prepareURL = 'https://medical-communications.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=' + updateId + '&option=scaner&value=update&data=' + data;
                fetch(prepareURL)
                    .then(response => response.json())
                    .then(json => updateUserData(json, updateId, name, role, event, company))
                    .catch(error => presentToast(error));
            }else{
                presentToast(message, "toast-error"); //
            }
        }
        if (newUserName && newUserPassword && newUserRole && newUserEvent && newUserCompany) {
            const name = newUserName ? newUserName.value : "";
            const pass = newUserPassword ? newUserPassword.value : "";
            const role = newUserRole ? newUserRole.value : 0;
            const event = newUserEvent ? newUserEvent.value : 0;
            const company = newUserCompany ? newUserCompany.value : 0;
            // console.log(name, pass, role, event);
            let test = true;
            let message = "";
            if (name == "" || /\s/g.test(name) || name.indexOf(',') > -1 || pass == "" || /\s/g.test(pass) || pass.indexOf(',') > -1) {
                test = false;
                message = "Username und Password dürfen keine Leerzeichen oder Komma haben";
            }
            if(role == undefined){
                test = false;
                message += (message != "") ? ", die Role muss bestimmt werden" : "Die Role muss bestimmt werden";
            }
            if(event == undefined){
                test = false;
                message += (message != "") ? ", das Event muss bestimmt werden" : "Das Event muss bestimmt werden";
            }
            if(company == undefined){
                test = false;
                message += (message != "") ? ", Die Firma muss bestimmt werden" : "Die Firma muss bestimmt werden";
            }

            if(test){
                // console.log("creating new user");
                const data = name + "," + pass + "," + role + "," + event + "," + company;
                let prepareURL = 'https://medical-communications.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=new&option=scaner&value=create&data=' + data;
                fetch(prepareURL)
                    .then(response => response.json())
                    .then(json => createUserData(json, name, role, event, company))
                    .catch(error => presentToast(error));
            }else{
                presentToast(message, "toast-error"); //
            }
        }
    }

    const updateUserData = (resp, id, name, role, event, company) => {
        if(resp.status == "Success"){
            let updateId = id+"";
            allUser.data.forEach((element, index) => {
                if(element.id === updateId){
                    element.name = name;
                    element.role = role.toString();
                    element.event_id = event.toString();
                    element.company_id = company.toString();
                    allUser.data[index] = element
                }
            });
            localStorage.setItem("all-user", JSON.stringify(allUser));
        }
        document.getElementById("updateUserPassword").value = "";
        presentToast("Daten gespeichert!", "toast-success"); //
    }

    const createUserData = (resp, name, role, event, company) => {
        if(resp.status == "Success"){
            // console.log(resp)
            const newId = resp.data.inserted_id;
            // console.log(newId);
            const newItem = {"id":newId,"name":name,"role":role.toString(),"event_id":event.toString(),"company_id":company.toString()}
            allUser.data.push(newItem);
            localStorage.setItem("all-user", JSON.stringify(allUser));
        }
        presentToast("Neuer User angelegt!", "toast-success"); //
        navigate("/user", { replace: true });
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="#" onClick={back}></IonBackButton>
                    </IonButtons>
                    {!isLoggedIn && <IonTitle>Logi-Vent Scaner Login</IonTitle>}
                    {isLoggedIn && <IonTitle>User management</IonTitle>}
                </IonToolbar>
            </IonHeader>
            {isLoggedIn && <><IonContent scroll="true" has-bouncing="true" id='scanView' className="ion-padding">
                <div className="scroll-area">
                    <UserData userId={userId} events={allEvents.data} roles={role} companies={allCompanies.data} />
                </div>
            </IonContent>
                <IonFooter>
                    <IonToolbar class="loggedin">
                        <IonButton expand="full" id="open-data" color="primary" onClick={saveUser}>Save</IonButton>
                    </IonToolbar>
                </IonFooter></>}
            {!isLoggedIn && <IonContent id='scanView' className="ion-padding"><Link to='/' ><IonButton expand="full">back to login</IonButton></Link></IonContent>}
        </>
    );

}

export default UserEditNew;
